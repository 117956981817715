import React, { FC, useEffect, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  Tooltip,
  Button,
  styled,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { circleButtonSize, circleIconSize } from '../SiteRentReport/PageHeader';

interface SortByProps {
  sortType: string
  onApplySort: (sortBy: string) => void;
}

const SortByComponent: FC<SortByProps> = ({ onApplySort, sortType }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = useState<string>('INVOICE_NO');
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(sortType != null && sortType != undefined) {
       setSelectedSort(sortType)
    }
  }, [sortType])

  const handleApplySort = () => {
    onApplySort(selectedSort);
    handleClose();
  };

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
      backgroundColor: '#ffffff !important', // Disable hover background
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff !important', // Disable selected background
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#ffffff !important', // Disable focus background
    },
  }));

  return (
    <>
      <Tooltip title="Sort Options">
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: 'black',
            width: { circleButtonSize },
            height: { circleButtonSize },
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
          }}
        >
          <SortIcon sx={{ width: circleIconSize, height: circleIconSize }} />
        </IconButton>
      </Tooltip>

      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: 300 } }}
      >
        <CustomMenuItem>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5">
                Sort by
              </Typography>
              <RadioGroup
                value={selectedSort}
                onChange={(e) => setSelectedSort(e.target.value)}
              >
                <FormControlLabel
                  value="INVOICE_NO"
                  control={<Radio />}
                  label="Invoice No"
                />
                <FormControlLabel
                  value="INVOICE_DATE"
                  control={<Radio />}
                  label="Invoice Date"
                />
                <FormControlLabel
                  value="ACTUAL_CREATE_DATE"
                  control={<Radio />}
                  label="Create Date"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </CustomMenuItem>

        <MenuItem>
          <Button
            onClick={handleApplySort}
            sx={{ backgroundColor: '#4caf50' }}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortByComponent;
