import { FC, useEffect, useState } from 'react';
import { Typography, Grid, Button, IconButton, Tooltip, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useAppDispatch } from '../../../hooks';
import { getFormattedEndDateString, getFormattedStartDateString } from '../../../utils';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { showAlertAction } from '../../../store/alerts';
export const circleButtonSize = '32px'
export const circleIconSize = '18px'
import AddIcon from '@mui/icons-material/Add'
import CreateInvoicesDialog from './CreateInvoicesDialog';
import Sort from './SortBy';
import SortByComponent from './SortBy';
import { getRecentBillsDataAction } from './actions';



const RecentBillsLedgerHeader: FC = () => {
  const today = new Date();
  const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 1);

  const [fromDate, setFromDate] = useState<Date | null>(sixMonthsAgo);
  const [sortType, setSortType] = useState<string>('INVOICE_NO');

  const [toDate, setToDate] = useState<Date | null>(today);
  const [showCreateInvoices, setShowCreateInvoices] = useState(false);

  const dispatchAction = useAppDispatch();
  const startDateChangeHandler = (value: Date | null) => {
    if (value) {
      setFromDate(value);
      if (toDate && toDate.getTime() < value.getTime()) {
        setToDate(value);
      }
    }
  };

  return (
    <>
      <Grid container alignItems="stretch">
        <Grid item md={4}>
          <Typography variant="h3" component="h3" gutterBottom pl={2.5}>
           Open Sites Last invoice
          </Typography>
        
        </Grid>
   
        <Grid item  md={1} sx={{ width: '5%' }}>
           
          
        </Grid>
        <Grid
          md={7}
          item
          sx={{ textAlign: 'right', display: 'flex', justifyContent: 'right', alignItems: 'center' }}
        >
           <SortByComponent sortType={sortType}  onApplySort={(type) => {
            dispatchAction(getRecentBillsDataAction(type))
           }} />
          <Button
            data-cy='stock_catgory_add_new_type'
            startIcon={<AddIcon />}
            variant="contained"
            sx={{ backgroundColor: '#eb627a', width: 260, height: 41.5, ml:4 }}
            onClick={() => {
              setShowCreateInvoices(true)
            }}
          >CREATE INVOICES</Button>
         
        </Grid>
      </Grid>
      {showCreateInvoices && (
        <CreateInvoicesDialog onClose={(success) => {
          if(success == true) {
            setSortType('ACTUAL_CREATE_DATE')
            dispatchAction(getRecentBillsDataAction('ACTUAL_CREATE_DATE'))
          }
          setShowCreateInvoices(false)
        }} />
      )}
    </>
  );
};

export default RecentBillsLedgerHeader;
